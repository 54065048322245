<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="選んでください" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>ホイッスル ユーザー リチャージ契約</h2>
		<p>この Whistle ユーザー リチャージ契約 (以下、「本契約」といいます) は、お客様と CLICK ADVERTISING & MEDIA PTE. LTD. (以下、「当社」といいます) との間で締結され、本契約は契約上の効力を持ちます。 お客様の正当な権利と利益を保護するため、本契約のすべての条項、特に当社の責任を免除または制限する条項（以下、「免責条項」といいます）に同意し承諾する前に、各条項の内容をよく読み、十分に理解してください。 」）、お客様の権利を制限する条項（以下「制限条項」といいます）、紛争の解決方法および管轄権を規定する条項。 前述の免責条項、制限事項、紛争解決方法、およびその他の重要な条件は、これらの条件を確認すると、特定の状況下で消極的、不便、損失を引き起こす可能性があることを思い出させるために、太字またはその他の合理的な方法で強調表示されます。本契約への同意を確認する前、または当社のソフトウェア サービスを使用する前に、前条の内容を確認してください。 </p>
		<p>お客様が 18 歳未満の場合は、法定保護者同伴の上、本規約をお読みいただき、当社が提供するサービスの利用および当社への料金の支払いについて、保護者の同意と承認を得てください。当社のソフトウェア サービスは、本契約、「個人情報保護ポリシー」、「子供向けユーザー契約」、および「子供向け個人情報保護ポリシー」のすべての条項を遵守した場合にのみ利用できます。 あなたが未成年者の法定後見人である場合。 あなたが 14 歳未満の未成年者 (14 歳を含む)、またはその法的保護者の場合。 </p>
		<p>本契約または当社のソフトウェア サービスに関してご意見やご提案がある場合は、本契約で合意されているとおり当社のカスタマー サービス部門にご連絡いただければ対応させていただきます。 「同意する」ボタンまたは「同意する」ボタンをクリックするか、当社のサービスをインストール、使用、登録、またはその他の方法でアクセスすると、本契約の全内容を詳細に読んで理解し、本契約の規定に従うことに同意したものとみなされます。同意するため、クリックする前に必ずこの同意書を注意深く完全にお読みください。 </p>
		<h2>第 1 条 サービス規則</h2>
		<p>1. 「スター ビーンズ」は、このプラットフォームでの関連消費のためにこのプラットフォームによって提供される仮想小道具です。「スター ビーンズ」を使用して、このプラットフォームで仮想ギフトやその他の製品またはサービスを購入できます（具体的には、製品やサービスについては、該当する製品やサービスのページ説明をご確認ください）、購入したバーチャルギフトをアンカーまたはプラットフォーム作成者に贈呈してください。 ただし、「スター豆」はいかなる場合も人民元と交換することはできませんので、必要に応じて必要な量の「スター豆」を購入してください。 </p>
		<p>2. リチャージプロセス中は、アカウントを注意深く確認し、関連する操作オプションを慎重に選択してください。 口座番号の誤入力、誤った操作、チャージや請求方法の理解または十分な理解不足等により、お客様ご自身の権利利益が侵害された場合には、お客様ご自身の権利利益が侵害されることがあります。自己責任であり、当プラットフォームはいかなる補償も補償もしません。 </p>
		<p>3. 必ず本プラットフォームが指定する正規のチャージ方法を使用してチャージしてください。 本プラットフォームが指定していないチャージ方法や不正な方法でチャージした場合、本プラットフォームはチャージが円滑かつ正確に完了することを保証するものではなく、その他のリスクが生じる可能性があります。その結果、利益が損なわれた場合、お客様は自己の責任で責任を負います。本プラットフォームは、取り扱いに関していかなる補償も補償も行わず、相応の責任を負うものとします。また、本プラットフォームは、お客様のアカウント資格の一部または全部を停止する権利を留保します。 、アカウント残高を凍結し、短期または永久にアカウントを禁止し、いつでもさまざまなサービスの利用を禁止します。サービスをリチャージする権利。 </p>
		<p>4. 「Xingdou」のリチャージは、事業運営のニーズに基づいてこのプラットフォームが提供する 1 回限りのサービスであり、「Xingdou」は、理由なく返還できる保証金、前払い、その他の商品や所有権ではありません。 ユーザーのリチャージが成功した後、プラットフォームのリチャージサービスは提供されており、プラットフォームは今後、逆交換または転送サービスを提供せず、プラットフォームは正当な理由がない限り払い戻しサービスを提供しません（正当な理由とは、プラットフォームが運営を停止または提供を停止することを指します） 「Xingdou」および「Xingdou」に関連するすべての機能およびサービスを提供するか、または法令でこのプラットフォームを返還することが明確に規定されている場合）、特定の操作の前に必ず慎重に選択してください。 </p>
		<p>5. プラットフォームがシステム障害またはその他の理由によって引き起こされた処理エラーを発見した場合、それがプラットフォームにとって有益であるかお客様にとって有益であるかにかかわらず、プラットフォームは電子メール、テキスト メッセージ、またはメッセージでお客様に通知した後、それを修正する権利を有します。その他の合理的な手段によるエラー。 この措置により、実際に受け取る「スタービーン」の数が本来受け取るべき「スタービーン」を下回った場合、プラットフォームは処理エラーを確認後、できるだけ早くアカウントに差額を補填します。 エラーにより、実際に受け取るべきよりも多くの「スター Beans」を受け取った場合、プラットフォームは、エラーの性質や原因に関係なく、その差額をアカウントから直接差し引く権利を有します。 </p>
		<p>6. 当社の製品機能に応じて、当社が運営する他の製品およびプラットフォーム（以下「関連プラットフォーム」といいます）のリチャージアカウントで購入したバーチャルプロップと、購入した「スタービーンズ」を組み合わせる場合があります。現時点では、このプラットフォーム上の関連プラットフォームからの仮想プロップの使用も、この契約の対象となります (関連プラットフォームからの前述の仮想プロップが「Xingdou」と呼ばれるか、その他の名前であるかに関係なく)逆に、上記の方法で関連プラットフォームで Star Bean が使用される場合、関連プラットフォームでの「Star Bean」の使用には、関連プラットフォームのリチャージ契約および関連する規則と条件も適用されます。 </p>
		<p>7. 各リチャージ方法に対応するチャネルプロバイダーは、その基準に従って関連するリチャージチャネル手数料を設定し、ユーザーがリチャージするときにそれを請求する場合があります。各リチャージ方法のチャネルプロバイダーのサービス料金に必ず注意し、選択してください。充電方法はご自身のニーズに合わせて選択してください。 </p>
		<p>8. 「Xingdou」は購入後に人民元に逆変換することはできず、本規約に規定されている正当な理由がない限り、プラットフォームは返金サービスを提供しません。 </p>
		<h2>第 2 条の権利表明</h2>
		<p>1. お客様は、このプラットフォームが、取引のセキュリティおよびその他の考慮事項 (取引制限、取引数などを含むがこれらに限定されない) に基づいて、取引関連事項を随時設定する権利を有することを理解し、これに同意するものとします。 </p>
		<p>2. リチャージ プロセスに第三者が提供する関連サービスが含まれる場合、本契約に従うことに加えて、第三者の契約および関連規則にも同意し、従う必要があります。いずれの場合も、この第三者については第三者および第三者が提供する関連サービスに起因する紛争については、お客様と第三者との間で解決するものとし、本プラットフォームは、お客様または第三者に対して一切の責任を負うものではありません。 </p>
		<p>3. お客様自身の理由または「Xingdou」購入サービスを提供する際のエラーにより、このプラットフォームが「Xingdou」購入サービスを提供できないことに起因する損失または責任はお客様の単独の責任であり、このプラットフォームは一切の責任を負いません. 以下を含むがこれらに限定されない責任:</p>
		<ul>
		<li>• (1) 憲法で定められた基本原則に反対する。</li>
		<li>• (2) Whistle アカウントが無効、紛失、またはブロックされているため。</li>
		<li>• (3) 認証されていない第三者の支払いアカウントの使用または自分以外の第三者の支払いアカウントの使用を含む、お客様の拘束された第三者の支払い機関アカウントに起因する損失または責任。支払いアカウントが凍結または差し押さえられた。</li>
		<li>• (4) パスワードを他人に伝えたために生じた物的損害</li>
		<li>• (5) お客様の個人的な故意または重過失によって生じた物的損害。 </li>
		</ul>
		<p>4. 以下の条件によりプラットフォームのリチャージ サービスが使用できない場合、または仮想資産を失った場合、お客様はプラットフォームが以下を含む (ただしこれに限定されない) 損害に対して責任を負わないことに同意するものとします。</p>
		<h2>ホイッスル ユーザー リチャージ契約</h2>
		<p>この Whistle ユーザー リチャージ契約 (以下、「本契約」といいます) は、お客様と CLICK ADVERTISING & MEDIA PTE. LTD. (以下、「当社」といいます) との間で締結され、本契約は契約上の効力を持ちます。 お客様の正当な権利と利益を保護するため、本契約のすべての条項、特に当社の責任を免除または制限する条項（以下、「免責条項」といいます）に同意し承諾する前に、各条項の内容をよく読み、十分に理解してください。 」）、お客様の権利を制限する条項（以下「制限条項」といいます）、紛争の解決方法および管轄権を規定する条項。 前述の免責条項、制限事項、紛争解決方法、およびその他の重要な条件は、これらの条件を確認すると、特定の状況下で消極的、不便、損失を引き起こす可能性があることを思い出させるために、太字またはその他の合理的な方法で強調表示されます。本契約への同意を確認する前、または当社のソフトウェア サービスを使用する前に、前条の内容を確認してください。 </p>
		<p>お客様が 18 歳未満の場合は、法定保護者同伴の上、本規約をお読みいただき、当社が提供するサービスの利用および当社への料金の支払いについて、保護者の同意と承認を得てください。当社のソフトウェア サービスは、本契約、「個人情報保護ポリシー」、「子供向けユーザー契約」、および「子供向け個人情報保護ポリシー」のすべての条項を遵守した場合にのみ利用できます。 あなたが未成年者の法定後見人である場合。 あなたが 14 歳未満の未成年者 (14 歳を含む)、またはその法的保護者の場合。 </p>
		<p>本契約または当社のソフトウェア サービスに関してご意見やご提案がある場合は、本契約で合意されているとおり当社のカスタマー サービス部門にご連絡いただければ対応させていただきます。 「同意する」ボタンまたは「同意する」ボタンをクリックするか、当社のサービスをインストール、使用、登録、またはその他の方法でアクセスすると、本契約の全内容を詳細に読んで理解し、本契約の規定に従うことに同意したものとみなされます。同意するため、クリックする前に必ずこの同意書を注意深く完全にお読みください。 </p>
		<h2>第 1 条 サービス規則</h2>
		<p>1. 「スター ビーンズ」は、このプラットフォームでの関連消費のためにこのプラットフォームによって提供される仮想小道具です。「スター ビーンズ」を使用して、このプラットフォームで仮想ギフトやその他の製品またはサービスを購入できます（具体的には、製品やサービスについては、該当する製品やサービスのページ説明をご確認ください）、購入したバーチャルギフトをアンカーまたはプラットフォーム作成者に贈呈してください。 ただし、「スター豆」はいかなる場合も人民元と交換することはできませんので、必要に応じて必要な量の「スター豆」を購入してください。 </p>
		<p>2. リチャージプロセス中は、アカウントを注意深く確認し、関連する操作オプションを慎重に選択してください。 口座番号の誤入力、誤った操作、チャージや請求方法の理解または十分な理解不足等により、お客様ご自身の権利利益が侵害された場合には、お客様ご自身の権利利益が侵害されることがあります。自己責任であり、当プラットフォームはいかなる補償も補償もしません。 </p>
		<p>3. 必ず本プラットフォームが指定する正規のチャージ方法を使用してチャージしてください。 本プラットフォームが指定していないチャージ方法や不正な方法でチャージした場合、本プラットフォームはチャージが円滑かつ正確に完了することを保証するものではなく、その他のリスクが生じる可能性があります。その結果、利益が損なわれた場合、お客様は自己の責任で責任を負います。本プラットフォームは、取り扱いに関していかなる補償も補償も行わず、相応の責任を負うものとします。また、本プラットフォームは、お客様のアカウント資格の一部または全部を停止する権利を留保します。 、アカウント残高を凍結し、短期または永久にアカウントを禁止し、いつでもさまざまなサービスの利用を禁止します。サービスをリチャージする権利。 </p>
		<p>4. 「Xingdou」のリチャージは、事業運営のニーズに基づいてこのプラットフォームが提供する 1 回限りのサービスであり、「Xingdou」は、理由なく返還できる保証金、前払い、その他の商品や所有権ではありません。 ユーザーのリチャージが成功した後、プラットフォームのリチャージサービスは提供されており、プラットフォームは今後、逆交換または転送サービスを提供せず、プラットフォームは正当な理由がない限り払い戻しサービスを提供しません（正当な理由とは、プラットフォームが運営を停止または提供を停止することを指します） 「Xingdou」および「Xingdou」に関連するすべての機能およびサービスを提供するか、または法令でこのプラットフォームを返還することが明確に規定されている場合）、特定の操作の前に必ず慎重に選択してください。 </p>
		<p>5. プラットフォームがシステム障害またはその他の理由によって引き起こされた処理エラーを発見した場合、それがプラットフォームにとって有益であるかお客様にとって有益であるかにかかわらず、プラットフォームは電子メール、テキスト メッセージ、またはメッセージでお客様に通知した後、それを修正する権利を有します。その他の合理的な手段によるエラー。 この措置により、実際に受け取る「スタービーン」の数が本来受け取るべき「スタービーン」を下回った場合、プラットフォームは処理エラーを確認後、できるだけ早くアカウントに差額を補填します。 エラーにより、実際に受け取るべきよりも多くの「スター Beans」を受け取った場合、プラットフォームは、エラーの性質や原因に関係なく、その差額をアカウントから直接差し引く権利を有します。 </p>
		<p>6. 当社の製品機能に応じて、当社が運営する他の製品およびプラットフォーム（以下「関連プラットフォーム」といいます）のリチャージアカウントで購入したバーチャルプロップと、購入した「スタービーンズ」を組み合わせる場合があります。現時点では、このプラットフォーム上の関連プラットフォームからの仮想プロップの使用も、この契約の対象となります (関連プラットフォームからの前述の仮想プロップが「Xingdou」と呼ばれるか、その他の名前であるかに関係なく)逆に、上記の方法で関連プラットフォームで Star Bean が使用される場合、関連プラットフォームでの「Star Bean」の使用には、関連プラットフォームのリチャージ契約および関連する規則と条件も適用されます。 </p>
		<p>7. 各リチャージ方法に対応するチャネルプロバイダーは、その基準に従って関連するリチャージチャネル手数料を設定し、ユーザーがリチャージするときにそれを請求する場合があります。各リチャージ方法のチャネルプロバイダーのサービス料金に必ず注意し、選択してください。充電方法はご自身のニーズに合わせて選択してください。 </p>
		<p>8. 「Xingdou」は購入後に人民元に逆変換することはできず、本規約に規定されている正当な理由がない限り、プラットフォームは返金サービスを提供しません。 </p>
		<h2>第 2 条の権利表明</h2>
		<p>1. お客様は、このプラットフォームが、取引のセキュリティおよびその他の考慮事項 (取引制限、取引数などを含むがこれらに限定されない) に基づいて、取引関連事項を随時設定する権利を有することを理解し、これに同意するものとします。 </p>
		<p>2. リチャージ プロセスに第三者が提供する関連サービスが含まれる場合、本契約に従うことに加えて、第三者の契約および関連規則にも同意し、従う必要があります。いずれの場合も、この第三者については第三者および第三者が提供する関連サービスに起因する紛争については、お客様と第三者との間で解決するものとし、本プラットフォームは、お客様または第三者に対して一切の責任を負うものではありません。 </p>
		<p>3. お客様自身の理由または「Xingdou」購入サービスを提供する際のエラーにより、このプラットフォームが「Xingdou」購入サービスを提供できないことに起因する損失または責任はお客様の単独の責任であり、このプラットフォームは一切の責任を負いません. 以下を含むがこれらに限定されない責任:</p>
		<ul>
		<li>• (1) 憲法で定められた基本原則に反対する。</li>
		<li>• (2) Whistle アカウントが無効、紛失、またはブロックされているため。</li>
		<li>• (3) 認証されていない第三者の支払いアカウントの使用または自分以外の第三者の支払いアカウントの使用を含む、お客様の拘束された第三者の支払い機関アカウントに起因する損失または責任。支払いアカウントが凍結または差し押さえられた。</li>
		<li>• (4) パスワードを他人に伝えたために生じた物的損害</li>
		<li>• (5) お客様の個人的な故意または重過失によって生じた物的損害。 </li>
		</ul>
		<p>4. 以下の条件によりプラットフォームのリチャージ サービスが使用できない場合、または仮想資産を失った場合、お客様はプラットフォームが以下を含む (ただしこれに限定されない) 損害に対して責任を負わないことに同意するものとします。</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/RechargeAgreementZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/RechargeAgreementEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/RechargeAgreementKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/RechargeAgreementJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h3 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
	
	li {
		font-size: 0.3rem;
	}
</style>